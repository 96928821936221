import type { IconProps } from '@/common/types/icon';
import type { FC } from 'react';

const Logo: FC<Omit<IconProps, 'size'>> = (props) => {
  const { isHover, type = 'white', ...rest } = props;

  const byTypeStyle: { [key: string]: string } = {
    black: '#191919',
    white: isHover ? '#191919' : 'white'
  };
  return (
    <svg
      {...rest}
      width="78"
      height="18"
      viewBox="0 0 78 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_26652_4783)">
        <path
          fill={byTypeStyle[type]}
          d="M41.915 17.4308H45.9302V0.427734H41.915V6.97854H34.6459V0.427734H30.6306V17.4308H34.6459V11.1861V10.8604L41.915 10.88V11.1861V17.4308Z"
        />
        <path
          fill={byTypeStyle[type]}
          d="M11.2098 10.6367L3.61491 0L0 1.87222V17.4427H3.92891V7.19058L11.8849 18H11.9006L15.1779 16.273V0.443524H11.2098V10.6367Z"
        />
        <path
          fill={byTypeStyle[type]}
          d="M65.3117 7.19058L73.2677 18H73.2834L76.5607 16.273V0.443524H72.5926V10.6367L64.9977 0L61.3828 1.87222V17.4427H65.3117V7.19058Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_26652_4783">
          <rect width="76.5608" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
