/* eslint-disable react/style-prop-object */
import type { FC } from 'react';
import type { IconProps } from '@/common/types/icon';

const Hamburger: FC<Omit<IconProps, 'size'>> = (props) => {
  const { fill, ...rest } = props;
  return (
    <svg
      {...rest}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="2" width="18" height="2" fill={fill || 'white'} />
      <rect x="1" y="9" width="18" height="2" fill={fill || 'white'} />
      <rect x="1" y="16" width="18" height="2" fill={fill || 'white'} />
    </svg>
  );
};

export default Hamburger;
