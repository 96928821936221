import SmallCircle from '@/common/icons/SmallCircle';
import { helper } from '@/common/utils';
import { merge } from '@/styles/tailwind/src';
import Link from 'next/link';
import React from 'react';

interface NavLinkProps {
  href: string;
  type?: 'main' | 'sub';
  isHover?: boolean;
  isActive: boolean;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  iconVisible?: boolean;
  children: React.ReactNode;
  className?: string;
}
type Typ1 = NavLinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>;

function NavLink({
  href,
  isActive,
  children,
  isHover,
  iconVisible = true,
  onClick,
  type,
  className,
  ...rest
}: Typ1) {
  const isActiveIcon = isActive && iconVisible;
  const { getMatchStyles } = helper;
  const target = helper.isOutLink(href) ? '_blank' : '_self';
  const styles = {
    container: merge('relative flex ', className),
    icon: merge(
      'invisible opacity-0',
      getMatchStyles(!!(isActiveIcon || isHover), 'visible opacity-100'),
      getMatchStyles(type === 'sub', 'hidden')
    )
  };
  return (
    <Link
      aria-label={`move to ${href}`}
      {...rest}
      onClick={onClick}
      href={href}
      target={target}
      className={styles.container}
    >
      {children}
      {iconVisible && (
        <div className={styles.icon}>
          <SmallCircle fill="#191919" />
        </div>
      )}
    </Link>
  );
}

export default NavLink;
