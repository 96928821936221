/* eslint-disable unused-imports/no-unused-vars */
import useRoute from '@/common/hooks/useRoute';
import CallMade from '@/common/icons/CallMade';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { twJoin } from 'tailwind-merge';
import MainNavs from './MainNavs';
import SubNavs from './SubNavs';
import { NavsProps } from './type';

export default function Navs(props: NavsProps) {
  const { t, ready } = useTranslation('common');
  const { path, events, handleMouse, logo, right } = props;
  const isHome = path === '/';
  const { isHover } = events;
  const iconComponent = <CallMade defaultColor={isHome ? 'white' : 'black'} isHover={isHover} />;
  const routeOptions = {
    iconNeedMainTitles: [t('nav.careers.name'), t('nav.insideNhn.name')],
    iconComponent,
    updates: [isHover || false, isHome]
  };

  const { mainWithSubNavs: navs, notSubPathLists, noWrapLists } = useRoute(routeOptions);
  const subs = navs.map(({ main, ...rest }) => rest);
  const mainNavsProps = {
    list: {
      navs,
      notSubPathLists,
      noWrapLists
    },
    handleMouse,
    events,
    path
  };

  const subNavsProps = {
    list: {
      noWrapLists,
      subs
    },
    events,
    path
  };
  if (!navs || !ready) return null;

  return (
    <nav className={twJoin('mx-auto flex h-full w-1360 min-w-1360 justify-start pl-80')}>
      <Link className="mt-3" aria-label="Home link" href="/">
        {logo && logo}
      </Link>
      <MainNavs {...mainNavsProps}>
        <SubNavs index={0} {...subNavsProps} />
      </MainNavs>
      {right && right}
    </nav>
  );
}
