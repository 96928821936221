import { useState } from 'react';
import { useBoolean } from '../useBoolean';

const useNavLink = () => {
  const [hoverIndex, setHoverIndex] = useState(-1);
  const { off, on, value: mainLinkHover } = useBoolean();

  const isTextHover = (index: number) => (mainLinkHover ? hoverIndex === index : false);
  const isLinkhover = (index: number) => isTextHover(index);

  const handleChangeHoverIndex = (index: number) => {
    setHoverIndex(index);
    on();
  };
  const handleChangeHoverLeave = () => {
    off();
  };

  return { handleChangeHoverIndex, handleChangeHoverLeave, isTextHover, isLinkhover };
};
export default useNavLink;
