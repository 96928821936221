import layout from '@/common/constants/layout';
import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import { useHelper } from '@/common/hooks/useHelper';
import usePathCheck from '@/common/hooks/usePathCheck';
import Footer from '@/components/unit/Footer';
import Header from '@/components/unit/Header';
import { merge } from '@/styles/tailwind/src';
import { type FC, type ReactNode } from 'react';
import { twJoin } from 'tailwind-merge';

export interface LayoutProps {
  children: ReactNode;
  hideLayout?: boolean;
  hideLayoutFooter?: boolean;
}

const DefaultLayout: FC<LayoutProps> = ({ children, hideLayout, hideLayoutFooter = false }) => {
  const { getCurrentLanguageFont } = useHelper();
  const { asPathCheckArray } = usePathCheck();
  const isNotStyle = asPathCheckArray(['/', '/proposal', '/privacy', '/underconstruction']);
  const isFooter = asPathCheckArray(['/proposal', '/privacy']);
  const styles = {
    container: useDeviceClasses({
      common: merge('flex min-h-screen w-full flex-col', isNotStyle ? 'pt-0' : 'pt-80'),
      desktop: !isFooter ? layout.desktopWidth : '',
      mobile: 'pt-0'
    })
  };
  return (
    <>
      {!hideLayout && <Header />}
      <main
        id="defaultLayout"
        className={twJoin(
          styles.container,
          getCurrentLanguageFont(),
          useDeviceClasses({ mobile: 'child:break-normal' })
        )}
      >
        {children}
      </main>
      {!hideLayout && !hideLayoutFooter && <Footer />}
    </>
  );
};

export default DefaultLayout;
