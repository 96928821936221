import { useState } from 'react';
import { useLayoutEffect } from '../useLayoutEffect';

const useBodyOverflow = () => {
  const [isToggled, setIsToggled] = useState(false);

  const overflowHidden = () => setIsToggled(true);
  const overflowAuto = () => setIsToggled(false);
  const toggle = () => {
    setIsToggled(!isToggled);
  };

  useLayoutEffect(() => {
    // 스크롤바 너비에 따라 숨김 또는 자동 설정
    document.body.style.overflow = isToggled ? 'hidden' : 'auto';
  }, [isToggled]);

  return { isToggled, toggle, overflowHidden, overflowAuto };
};

export default useBodyOverflow;
