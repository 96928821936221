import React, { useRef } from 'react';
import MainNavsItem from './MainNavsItem';
import { MainNavsProps, NavItem } from './type';

export default function MainNavs({
  events,
  path: pathname,
  list,
  handleMouse,
  children
}: MainNavsProps) {
  const containerEl = useRef<HTMLUListElement>(null);
  return (
    <ul ref={containerEl} className="relative ml-64 flex overflow-hidden transition-colors">
      {list.navs.map(({ main }: NavItem, index) => (
        <MainNavsItem
          key={main.title}
          list={list}
          main={main}
          itemIndex={index}
          handleMouseOn={handleMouse.on}
          isHover={events.isHover || false}
          pathname={pathname}
        >
          <>
            {React.Children.map(children, (child) => {
              return React.cloneElement(child, { index, hover: events.isHover });
            })}
          </>
        </MainNavsItem>
      ))}
    </ul>
  );
}
