/* eslint-disable unused-imports/no-unused-vars */
import type { FC } from 'react';
import React from 'react';
import type { IconProps } from '../types/icon';

const CallMade2: FC<IconProps> = (props) => {
  const { ...rest } = props;
  return (
    <svg
      {...rest}
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.12817 1.54297H9V8.41479"
        stroke="#57575B"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path d="M0.753906 9.50024L9.0001 1.5" stroke="#57575B" strokeWidth="1.5" />
    </svg>
  );
};

export default CallMade2;
