import { useIsMobile } from '@/common/contexts/IsMobileContext';
import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import { useLocale } from '@/common/hooks/useLocale';
import FooterPlus from '@/common/icons/FooterPlus';
import { helper } from '@/common/utils';
import { Popover, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState, type FC } from 'react';
import { twJoin } from 'tailwind-merge';
import FooterSocial from './FooterSocial';
import type { FooterProps, RightNavProps, SubNavProps } from './footer';

function NavItem({ title, link }: SubNavProps) {
  return (
    <a
      className={useDeviceClasses({
        mobile: 'h-48v px-20v py-13v',
        desktop: 'h-40 min-w-200 px-20 py-10',
        common: 'w-full text-left hover:bg-grayscale-12 hover:text-grayscale-1'
      })}
      key={title}
      target="_blank"
      href={link}
    >
      {title}
    </a>
  );
}

function RightNavItem({ title, subNav }: RightNavProps) {
  const isMobile = useIsMobile();
  const [modalLeft, setModalLeft] = useState(0);
  const { isKo } = useLocale();

  const boxWidth = isKo ? 92.39 : 126;
  const margin = 40;

  const setModalLeftPosition = (width: number) => {
    const box = helper.getVw(width, boxWidth);
    const space = helper.getVw(width, margin);
    const calcLeft = box + space;

    const left = isMobile && ['해외법인', 'GLOBAL SITE'].includes(title) ? calcLeft : 0;
    setModalLeft(left);
  };

  useEffect(() => {
    setModalLeftPosition(window.innerWidth);
    const handleResize = () => {
      setModalLeftPosition(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return window.addEventListener('resize', handleResize);
  }, []);

  const style = {
    wrapper: useDeviceClasses({
      desktop: 'relative',
      common: 'z-[1] flex items-center focus-within:outline-none group'
    }),
    title: useDeviceClasses({
      mobile: 'mr-16v h-22v text-body12-l-v',
      desktop: 'mr-8 h-24 text-body10-l',
      common: 'relative text-grayscale-1'
    }),
    icon: useDeviceClasses({
      mobile: 'h-28v w-28v',
      desktop: 'h-28 w-28',
      common: 'transition-all  hover:fill-primary-selected'
    }),
    modal: useDeviceClasses({
      mobile: `absolute bottom-38v z-[2] flex h-220v w-335v flex-col items-center overflow-x-hidden overflow-y-scroll rounded-10v bg-white  text-body12-m-v text-grayscale-3 shadow-light-2-2`,
      desktop:
        'absolute bottom-38 z-[2] right-0 flex h-184 w-200 flex-col items-center  rounded-10 bg-white  text-body11-m text-grayscale-3 shadow-light-2-2'
    })
  };
  const mobileModalStyle = {
    left: `${-modalLeft}px`
  };

  return (
    <Popover className="relative">
      {({ open }) => {
        return (
          <>
            <Popover.Button className={style.wrapper}>
              <span className={style.title}>{title}</span>
              <FooterPlus
                className={twJoin(
                  style.icon,
                  open ? 'rotate-45 fill-primary-selected' : 'rotate-0'
                )}
              />
              {/* <em className={style.icon}>
                <MoreButton isHover={isHover} isClicked={isClicked} />
              </em> */}
            </Popover.Button>
            <Transition
              as={Fragment}
              show={open}
              enter="transition linear origin-bottom"
              enterFrom="transform scale-y-75 opacity-0"
              enterTo="transform scale-y-100 opacity-100"
              leave="transition linear origin-bottom"
              leaveFrom="transform scale-y-100 opacity-100"
              leaveTo="transform scale-y-75 opacity-0"
            >
              <Popover.Panel
                className={twJoin(style.modal)}
                style={isMobile ? mobileModalStyle : {}}
              >
                <div
                  style={{ display: 'flex', height: '100%', flexDirection: 'column' }}
                  className="overflow-x-hidden overflow-y-scroll overscroll-contain py-4"
                >
                  {subNav &&
                    subNav.map(({ title: title2, link, description }) => (
                      <NavItem key={title2} title={title2} link={link} description={description} />
                      // <button
                      //   type="button"
                      //   className="h-48v w-full px-20v py-10v text-left hover:bg-grayscale-11"
                      //   key={nav.title}
                      //   onClick={() => {
                      //     window.open(nav.link);
                      //   }}
                      // >
                      //   {nav.title}
                      // </button>
                    ))}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        );
      }}
    </Popover>
  );
}

const FooterRightNav: FC<FooterProps> = ({ rightNav }) => {
  const style = {
    wrapper: useDeviceClasses({
      desktop: 'space-y-27',
      mobile: 'space-y-32v',
      common: 'flex flex-col'
    })
  };

  return (
    <div className={style.wrapper}>
      <div
        className={useDeviceClasses({
          mobile: 'space-x-40v',
          desktop: 'space-x-40',
          common: 'flex'
        })}
      >
        {rightNav &&
          rightNav.map((menu) => (
            <RightNavItem key={menu.title} title={menu.title} subNav={menu.subNav} />
          ))}
      </div>
      <FooterSocial />
    </div>
  );
};

export default FooterRightNav;
