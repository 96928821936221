import type { IconProps } from '@/common/types/icon';
import type { FC } from 'react';

const SideMenuClose: FC<Omit<IconProps, 'size'>> = (props) => {
  const { ...rest } = props;
  return (
    <svg
      {...rest}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 2L18 18" stroke="#36363D" strokeWidth="2" />
      <path d="M18 2L2 18" stroke="#36363D" strokeWidth="2" />
    </svg>
  );
};

export default SideMenuClose;
