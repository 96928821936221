import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import Instagram from '@/common/icons/social/Instagram';
import LinkedIn from '@/common/icons/social/LinkedIn';
import MeetUp from '@/common/icons/social/MeetUp';
import YouTube from '@/common/icons/social/YouTube';
import React, { FC } from 'react';

interface IconWrapperProps {
  icon: React.ReactNode;
  link: string;
}

const IconWrapper = ({ icon, link }: IconWrapperProps) => {
  const style = {
    round: useDeviceClasses({
      mobile: 'p-10v',
      desktop: 'p-10',
      common: 'border border-grayscale-10 bg-white rounded-100'
    })
  };

  return (
    <a aria-label={`move to ${link}`} href={link} target="_blank" className={style.round}>
      {icon}
    </a>
  );
};

const FooterSocial: FC = () => {
  const style = {
    wrapper: useDeviceClasses({
      mobile: 'space-x-10v',
      desktop: 'space-x-10 ml-auto',
      common: 'flex'
    }),
    icon: useDeviceClasses({
      mobile: 'w-20v h-20v',
      desktop: '',
      common: ''
    })
  };
  // 소셜 링크필요함!!
  return (
    <div className={style.wrapper}>
      <IconWrapper
        link="https://www.youtube.com/channel/UCMEfq7bqJwujrM5oEEhhmYw"
        icon={<YouTube className={style.icon} />}
      />
      <IconWrapper
        link="https://www.instagram.com/nhn_corp/"
        icon={<Instagram className={style.icon} />}
      />
      <IconWrapper
        link="https://www.linkedin.com/company/nhncorp/"
        icon={<LinkedIn className={style.icon} />}
      />
      <IconWrapper link="https://meetup.nhncloud.com/" icon={<MeetUp className={style.icon} />} />
    </div>
  );
};

export default FooterSocial;
