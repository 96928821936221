import type { IconProps } from '@/common/types/icon';
import type { FC } from 'react';

const FooterPlus: FC<Omit<IconProps, 'size'>> = (props) => {
  const { fill, ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      {...rest}
      viewBox="0 0 28 28"
      fill="none"
    >
      <circle cx="14" cy="14" r="13.5" fill={fill || '#36363D'} />
      <path d="M14 9V19" stroke="white" strokeWidth="2" />
      <path d="M19 14L9 14" stroke="white" strokeWidth="2" />
    </svg>
  );
};

export default FooterPlus;
