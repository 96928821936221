import React from 'react';
import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';

function FooterCopyright() {
  return (
    <div
      className={useDeviceClasses({
        mobile: 'text-body13-s-v',
        desktop: 'absolute text-body11-s pt-4',
        ENdesktop: 'relative',
        common: ' text-grayscale-6'
      })}
    >
      ©NHN Corp. All rights reserved.
    </div>
  );
}

export default FooterCopyright;
