import { useLayoutEffect } from '@/common/hooks/useLayoutEffect';
import { useNavLink } from '@/common/hooks/useNavLink';
import { useRouterType } from '@/common/store/state';
import { path } from '@/common/utils';
import { merge } from '@/styles/tailwind/src';
import { isNull } from 'lodash';
import { ReactElement, cloneElement, useRef } from 'react';
import NavLink from '../NavLink';
import { MainNavsList, NavMain } from './type';

interface IProps extends MainNavsList {
  pathname: string;
  isHover: boolean;
  handleMouseOn: () => void;
  main: NavMain;
  itemIndex: number;
  children: ReactElement;
}
type TLiRefType = HTMLLIElement | null;

export default function MainNavsItem({
  pathname,
  isHover,
  handleMouseOn,
  main,
  itemIndex,
  list,
  children
}: IProps) {
  const { handleChangeHoverIndex, handleChangeHoverLeave, isTextHover, isLinkhover } = useNavLink();
  const isHome = pathname === '/';
  const { isActivePath, getPath } = path;
  const liRefs = useRef<TLiRefType[]>([]);
  const { setRouterType } = useRouterType();
  const textStyle = isHover || !isHome ? 'text-grayscale-2' : 'text-white';

  const handleClick = () => {
    if (isHome) return;
    setRouterType('main');
  };

  const iconComponentWithSelectedProp =
    main.iconComponent &&
    cloneElement(main.iconComponent, {
      selected: isActivePath(getPath(main.link, list.notSubPathLists), pathname),
      isTextHover: isLinkhover(itemIndex)
    });
  const iconComponentWith: any = {
    CAREERS: 'min-w-71 w-71',
    'INSIDE NHN': 'min-w-89 w-89'
  };

  const handleEventListener = (type: 'addEventListener' | 'removeEventListener') => {
    liRefs.current.forEach((liRef) => {
      if (isNull(liRef)) return;
      liRef[type]('mouseenter', handleMouseOn);
    });
  };

  useLayoutEffect(() => {
    handleEventListener('addEventListener');
    return () => handleEventListener('removeEventListener');
  }, [handleMouseOn]);

  const isActive = isActivePath(getPath(main.link, list.notSubPathLists), pathname);
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <li
      ref={(el) => (liRefs.current[itemIndex] = el)}
      onClick={handleClick}
      key={main.title}
      className={merge(textStyle, 'mainLink flex pr-80')}
    >
      <NavLink
        onMouseEnter={() => handleChangeHoverIndex(itemIndex)}
        onMouseLeave={handleChangeHoverLeave}
        className={merge(
          'z-[120] h-fit text-body10-m ',
          iconComponentWith[main.title],
          isActive && 'text-body10-l'
        )}
        href={main.link}
        isHover={isTextHover(itemIndex)}
        isActive={isActive}
        iconVisible={!main.iconComponent}
      >
        <span className="min-w-max pr-3">{main.title}</span>
      </NavLink>
      {iconComponentWithSelectedProp}
      {children}
    </li>
  );
}
