import type { IconProps } from '@/common/types/icon';
import type { FC } from 'react';

const SmallCircle: FC<Omit<IconProps, 'size'>> = (props) => {
  const { fill, ...rest } = props;
  return (
    <svg
      {...rest}
      width="6"
      height="7"
      viewBox="0 0 6 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="3" cy="3.25" r="3" fill={fill} />
    </svg>
  );
};

export default SmallCircle;
