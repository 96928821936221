import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useRef, useState } from 'react';

type UseOutsideClickProps = (
  initialState: boolean
) => [any, boolean, Function, Dispatch<SetStateAction<boolean>>];

export const useOutsideClick: UseOutsideClickProps = (initialState) => {
  const modalMenuRef = useRef<HTMLButtonElement>(null);
  const [isActive, setIsActive] = useState(initialState);

  const setIsClicked = () => setIsActive((prev) => !prev);
  const pageClickEvent = (e: any) => {
    if (modalMenuRef.current !== null && !modalMenuRef.current.contains(e.target)) {
      setIsActive(!isActive);
    }
  };

  useEffect(() => {
    isActive && window.addEventListener('click', pageClickEvent, { passive: true });
    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [isActive]);

  return [modalMenuRef, isActive, setIsClicked, setIsActive];
};
