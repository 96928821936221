import { useBodyOverflow } from '@/common/hooks/useBodyOverflow';
import useRoute from '@/common/hooks/useRoute';
import type { RouteValue } from '@/common/route';
import { path } from '@/common/utils';
import NavLink from '@/components/unit/NavLink';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { merge } from '@/styles/tailwind/src';
import CallMade2 from '@/common/icons/CallMade2';
import { useRouterType } from '@/common/store/state';
import SideMenuHeader from './SideMenuHeader';
import { NavItem } from '../../Navs/type';

interface SideMenuProps {
  isOpenProp?: boolean;
}
const { isActivePath, getPath } = path;
const notSubPathLists: RouteValue[] = ['/careers', '/insideNhn'];

function Menu({ text }: { text: string }) {
  const isNeedIconText = ['CAREERS', 'INSIDE NHN'];
  const IconComponent = <CallMade2 className="relative top-8v h-10v w-10v" />;

  return (
    <div className={merge('flex whitespace-pre')}>
      <span>{text}</span>
      {isNeedIconText.includes(text) && <span className="pl-10v">{IconComponent}</span>}
    </div>
  );
}

export default function SideMenu({ isOpenProp = false }: SideMenuProps) {
  const { toggle: bodyOverFlowChangeToggle } = useBodyOverflow();
  const [isOpen, setIsOpen] = useState(isOpenProp);
  const [animationClass, setAnimationClass] = useState('');
  const [initClass, setInitClass] = useState('');
  const router = useRouter();
  const { mainNavs: navs } = useRoute();

  const handleChangeOpen = (e?: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e?.stopPropagation();
    setIsOpen(!isOpen);
    bodyOverFlowChangeToggle();
  };

  const { setRouterType } = useRouterType();

  useEffect(() => {
    setRouterType('');
    setAnimationClass(
      isOpen ? 'h-screen pb-32 sidemenu-open ' : 'h-0 pb-0 sidemenu-close overflow-hidden'
    );
    setInitClass(isOpen ? 'bg-white' : 'bg-transparent delay-[450ms] duration-500');
  }, [isOpen]);

  return (
    <nav className="h-full  w-full items-center overflow-visible text-black">
      <SideMenuHeader isOpen={isOpen} onClick={handleChangeOpen} classNames={initClass} />
      <div
        className={merge(
          `sidemenu-close absolute left-0 top-55v z-[110] flex h-0 w-full flex-col bg-white px-20v pb-0 transition-all duration-500 ease-in-out`,
          animationClass
        )}
      >
        {/* <SideMenuHeader isOpen={isOpen} onClick={handleChangeOpen} /> */}
        <div className="z-[110] mt-20v flex w-full flex-1 flex-col overflow-scroll">
          <ul>
            {navs.map(({ main }: Omit<NavItem, 'sub'>) => {
              const isActive = isActivePath(getPath(main.link, notSubPathLists), router.pathname);
              const mobileLinkStyle = isActive
                ? 'text-grayscale-2 text-t7-l-v'
                : 'text-grayscale-3';
              return (
                <li key={main.title} className="flex h-64v w-full items-center text-t7-m-v">
                  <NavLink
                    href={main.link}
                    onClick={handleChangeOpen}
                    isActive={isActive}
                    className={mobileLinkStyle}
                    iconVisible={false}
                  >
                    <Menu key={main.title} text={main.title} />
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div />
    </nav>
  );
}
