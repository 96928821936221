import { useBoolean } from '@/common/hooks/useBoolean';
import { useHelper } from '@/common/hooks/useHelper';
import Logo from '@/common/icons/Logo';
import { merge } from '@/styles/tailwind/src';
import { useRouter } from 'next/router';
import DesktopView from '../DesktopView';
import LanguageSwitcher from '../LanguageSwitcher';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import useStyles from './hooks/useStyles';

export default function Header() {
  const { getCurrentLanguageFont } = useHelper();
  const router = useRouter();
  const { asPath } = router;
  const { on, off, value: hover } = useBoolean();
  const { headerContainer, headerInlineStyle, languageFill, logo, events } = useStyles({
    hover,
    asPath
  });
  return (
    <header
      id="header"
      onMouseLeave={off}
      className={merge(headerContainer, getCurrentLanguageFont())}
      style={{ ...headerInlineStyle }}
    >
      <MobileHeader />
      <DesktopView>
        <DesktopHeader
          path={asPath}
          handleMouse={{ on, off }}
          logo={<Logo className="transition-color" isHover={hover} type={logo} />}
          right={<LanguageSwitcher fillType={languageFill} />}
          events={events}
        />
      </DesktopView>
    </header>
  );
}
