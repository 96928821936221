import { useCallback, useState } from 'react';

const useBoolean = () => {
  const [value, setValue] = useState(false);

  const on = useCallback(() => setValue(true), []);
  const off = useCallback(() => setValue(false), []);
  const toggle = useCallback(() => setValue((state) => !state), []);

  return { off, on, toggle, value };
};

export default useBoolean;
