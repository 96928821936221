/* eslint-disable react/style-prop-object */
import type { FC } from 'react';
import type { IconProps } from '@/common/types/icon';

const LinkedIn: FC<Omit<IconProps, 'size'>> = (props) => {
  const { fill, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      {...rest}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.36702 2H16.7205C17.4498 2 18.0041 2.52514 18 3.24616V16.6288C18 17.3498 17.4498 18 16.7163 18H3.36702C2.63766 18 2 17.354 2 16.6288V3.24616C2 2.52514 2.63766 2 3.36702 2ZM5 14.9986H7.14801V8.3202H5V14.9986ZM6.14821 7.30423H6.13259C5.44522 7.30423 5 6.79262 5 6.15212C5 5.4999 5.45694 5 6.15992 5C6.86291 5 7.29251 5.496 7.30813 6.15212C7.30813 6.79262 6.86291 7.30423 6.14821 7.30423ZM12.852 14.9987H15V11.0971C15 9.09361 13.9338 8.1641 12.5239 8.1641C11.3875 8.1641 10.8993 8.8046 10.5868 9.24983V8.32032H8.43883V14.9987H10.5868V11.1948C10.5868 10.9995 10.6025 10.8081 10.661 10.6675C10.8173 10.277 11.1688 9.8747 11.7624 9.8747C12.5396 9.8747 12.852 10.4722 12.852 11.3471V14.9987Z"
        fill={fill || '#36363D'}
      />
    </svg>
  );
};

export default LinkedIn;
