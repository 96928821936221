import type { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import { twJoin } from 'tailwind-merge';
import layout from '@/common/constants/layout';
import type { FooterProps } from './footer';
import FooterNav from './FooterNav';

const Footer: FC = () => {
  const { t } = useTranslation('footer');
  const footerList: FooterProps = t('footer', { returnObjects: true });
  const containerStyles = useDeviceClasses({
    mobile: 'w-full',
    desktop: twJoin('max-h-184 h-184 w-full'),
    common: twJoin(
      'relative bottom-[0] left-0 flex border-t border-box border-grayscale-10 bg-white'
    )
  });
  const innerContainerStyles = useDeviceClasses({
    mobile: 'px-20v pt-32v w-full pb-48v',
    desktop: twJoin('items-start mt-48', layout.desktopLayout),
    common: 'border-box'
  });

  return (
    <footer id="footer" className={containerStyles}>
      <div id="footerContainer" className={innerContainerStyles}>
        <FooterNav leftNav={footerList?.leftNav} rightNav={footerList?.rightNav} />
        {/* <Copyright /> */}
      </div>
    </footer>
  );
};

export default Footer;
