/* eslint-disable unused-imports/no-unused-vars */
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import type { IconProps } from '../types/icon';

const CallMade: FC<IconProps> = (props) => {
  const [activeProp, setActiveProp] = useState('default');
  const priorityProps: Array<keyof IconProps> = ['selected', 'isTextHover', 'isHover'];
  const { isHover, defaultColor, isTextHover, ...rest } = props;

  const styles: { [key: string]: string } = {
    isTextHover: 'black',
    isHover: 'black',
    selected: 'black',

    default: defaultColor || 'white'
  };
  useEffect(() => {
    let activePropValue = 'default';

    // eslint-disable-next-line no-restricted-syntax
    for (const prop of priorityProps) {
      // eslint-disable-next-line react/destructuring-assignment
      if (props[prop]) {
        activePropValue = prop;

        break;
      }
    }
    setActiveProp(activePropValue);
  }, [props]);
  return (
    <svg
      {...rest}
      width="12"
      height="20"
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2193_13709)">
        <path d="M6 4.53125H10.9844V9.6" stroke={styles[activeProp]} strokeLinejoin="round" />
        <path d="M5.25488 10.3523L10.9844 4.53125" stroke={styles[activeProp]} />
      </g>
      <defs>
        <clipPath id="clip0_2193_13709">
          <rect width="12" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CallMade;
