/* eslint-disable react/style-prop-object */
import type { FC } from 'react';
import type { IconProps } from '@/common/types/icon';

const YouTube: FC<Omit<IconProps, 'size'>> = (props) => {
  const { fill, ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      {...rest}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.643 3.88829C17.3749 4.1023 17.95 4.7306 18.1459 5.5301H18.1446C18.5 6.97778 18.5 10 18.5 10C18.5 10 18.5 13.0222 18.1446 14.4699C17.9486 15.2694 17.3735 15.8977 16.6416 16.1117C15.3163 16.5 10 16.5 10 16.5C10 16.5 4.68365 16.5 3.35841 16.1117C2.62652 15.8977 2.05137 15.2694 1.85545 14.4699C1.5 13.0222 1.5 10 1.5 10C1.5 10 1.5 6.97778 1.85545 5.5301C2.05137 4.7306 2.62652 4.1023 3.35841 3.88829C4.68365 3.5 10 3.5 10 3.5C10 3.5 15.3163 3.5 16.643 3.88829ZM12.7179 9.99993L8.3 12.7852V7.21465L12.7179 9.99993Z"
        fill={fill || '#36363D'}
      />
    </svg>
  );
};

export default YouTube;
