/* eslint-disable react/style-prop-object */
import type { FC } from 'react';
import type { IconProps } from '@/common/types/icon';

const MeetUp: FC<Omit<IconProps, 'size'>> = (props) => {
  const { fill, ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      {...rest}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 2C3.00736 2 2 3.00736 2 4.25V15.75C2 16.9926 3.00736 18 4.25 18H15.75C16.9926 18 18 16.9926 18 15.75V4.25C18 3.00736 16.9926 2 15.75 2H4.25ZM6.46566 6C6.21486 6 6 6.20333 6 6.44067V13.559C6 13.8077 6.20324 14 6.45403 14C6.71645 14 6.91934 13.8077 6.91934 13.559V7.661L9.58225 11.367C9.68968 11.514 9.82106 11.5933 9.98802 11.5933C10.1553 11.5933 10.2747 11.514 10.3822 11.367L13.0567 7.64967V13.548C13.0567 13.7853 13.2716 14 13.5343 14C13.7851 14 14 13.7963 14 13.548V6.44067C14 6.20333 13.7851 6 13.5343 6H13.4269C13.2356 6 13.081 6.09033 12.9849 6.23733L10 10.486L7.01515 6.23733C6.91934 6.10167 6.77633 6 6.57309 6H6.46566Z"
        fill={fill || '#36363D'}
      />
    </svg>
  );
};

export default MeetUp;
