import type { FC } from 'react';

import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import FooterLeftNav from './FooterLeftNav';
import FooterRightNav from './FooterRightNav';
import type { FooterProps } from './footer';

const FooterNav: FC<FooterProps> = ({ leftNav, rightNav }) => (
  <div
    className={useDeviceClasses({
      mobile: 'flex-col items-start justify-center',
      desktop: 'w-full items-start justify-between',
      common: 'flex'
    })}
  >
    <FooterLeftNav leftNav={leftNav} />
    <FooterRightNav rightNav={rightNav} />
  </div>
);

export default FooterNav;
