import type { FC } from 'react';
import type { IconProps } from '@/common/types/icon';

const LocalLogo: FC<Omit<IconProps, 'size'>> = (props) => {
  const { fill, ...rest } = props;
  return (
    <svg
      {...rest}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="9.25" stroke={fill || 'white'} strokeWidth="1.5" />
      <path
        d="M14.25 10C14.25 12.6702 13.7072 15.0506 12.8647 16.7357C11.9998 18.4656 10.9436 19.25 10 19.25C9.05641 19.25 8.00024 18.4656 7.13529 16.7357C6.29276 15.0506 5.75 12.6702 5.75 10C5.75 7.32976 6.29276 4.9494 7.13529 3.26434C8.00024 1.53443 9.05641 0.75 10 0.75C10.9436 0.75 11.9998 1.53443 12.8647 3.26434C13.7072 4.9494 14.25 7.32976 14.25 10Z"
        stroke={fill || 'white'}
        strokeWidth="1.5"
      />
      <rect x="1" y="6.16663" width="18" height="1.5" fill={fill || 'white'} />
      <rect x="1" y="12.3334" width="18" height="1.5" fill={fill || 'white'} />
    </svg>
  );
};

export default LocalLogo;
