import { useLayoutEffect } from '@/common/hooks/useLayoutEffect';
import { useScroll } from '@/common/hooks/useScroll';
import Hamburger from '@/common/icons/Hamburger';
import Logo from '@/common/icons/Logo';
import SideMenuClose from '@/common/icons/SideMenuClose';
import route from '@/common/route';
import LanguageSwitcher from '@/components/unit/LanguageSwitcher';
import { merge } from '@/styles/tailwind/src';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';

interface SideMenuHeaderProps {
  isOpen: boolean;
  onClick: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  classNames?: string;
}

export default function SideMenuHeader({ isOpen, onClick, classNames }: SideMenuHeaderProps) {
  const { y: scrollY } = useScroll();
  const [delayIsOpen, setDelayIsOpen] = useState(isOpen);
  const [propisOpen, setPropIsOpen] = useState(isOpen);
  const [logoType, setLogoType] = useState('white');
  const router = useRouter();
  const isHome = router.pathname === '/';
  const prevCloseButtonClick = useRef(false);
  const handleCloseClick = () => {
    prevCloseButtonClick.current = true;
    onClick();
    setTimeout(() => {
      prevCloseButtonClick.current = false;
    }, 450);
  };
  const handleLogoClick = useCallback(() => {
    window.scrollTo(0, 0);
    if (propisOpen) onClick();
  }, [onClick, propisOpen]);
  const buttons = [
    {
      id: 1,
      hidden: prevCloseButtonClick.current ? !delayIsOpen : !isOpen,
      iconComponent: <SideMenuClose className="h-20v w-20v" />,
      event: handleCloseClick
    },
    {
      id: 2,
      hidden: prevCloseButtonClick.current ? delayIsOpen : isOpen,
      iconComponent: (
        <Hamburger fill={isHome && scrollY < 50 ? 'white' : 'black'} className="h-20v w-20v" />
      ),
      event: onClick
    }
  ];

  useLayoutEffect(() => {
    let color = 'black';

    color = !isOpen && isHome ? 'white' : 'black';
    if (scrollY > 50) {
      color = 'black';
    }

    if (color === 'white' && prevCloseButtonClick.current) {
      setTimeout(() => {
        setLogoType(color);
      }, 450);
    } else {
      setLogoType(color);
    }
  }, [isOpen, router.pathname, scrollY]);

  useLayoutEffect(() => {
    setTimeout(() => {
      setDelayIsOpen(isOpen);
    }, 450);
  }, [isOpen]);
  useLayoutEffect(() => {
    setPropIsOpen(isOpen);
  }, [isOpen]);
  return (
    <div className={merge('flex h-56v items-center justify-between px-20v', classNames)}>
      <Link aria-label="Home link" href={route.main} onClick={handleLogoClick}>
        <Logo type={logoType} className={twJoin('h-18v w-72v')} />
      </Link>
      <div className="flex space-x-20v">
        <LanguageSwitcher fillType={logoType} />
        {buttons.map(({ id, hidden, iconComponent, event }) => {
          return (
            <button
              key={id}
              type="button"
              onClick={event}
              className={twJoin('flex', hidden ? 'hidden' : '')}
            >
              {iconComponent}
            </button>
          );
        })}
      </div>
    </div>
  );
}
