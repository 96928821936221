import SideMenu from '@/components/unit/Header/SideMenu';
import MobileView from '../MobileView';

export default function MobileHeader() {
  return (
    <MobileView>
      <SideMenu />
    </MobileView>
  );
}
