import { useRef } from 'react';
import { useLayoutEffect } from '../useLayoutEffect';

export default function useWheelDirection() {
  const wheelDirection = useRef<string>('init');

  useLayoutEffect(() => {
    const onWheel = (e: WheelEvent) => {
      wheelDirection.current = 'up';
      if (e.deltaY > 0) wheelDirection.current = 'down';
    };
    window.addEventListener('wheel', onWheel);
    return () => window.removeEventListener('wheel', onWheel);
  }, []);
  return { wheelDirection: wheelDirection.current };
}
