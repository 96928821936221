import type { RouteValue } from '@/common/route';
import { useRouterType } from '@/common/store/state';
import { path } from '@/common/utils';
import { merge } from '@/styles/tailwind/src';
import { twJoin, twMerge } from 'tailwind-merge';
import NavLink from '../NavLink';
import { NavItem, NavsProps } from './type';

interface SubNavsProps extends Pick<NavsProps, 'events' | 'path'> {
  list: { noWrapLists: RouteValue[]; subs: Pick<NavItem, 'sub'>[] };
  index: number;
}
const { isActivePath } = path;

export default function SubNavs({ list, path: pathname, index }: SubNavsProps) {
  const { setRouterType } = useRouterType();

  return (
    <ul id="subNav" className={twJoin('absolute pt-50')}>
      {list.subs[index]?.sub?.map(({ link, title }) => {
        return (
          <li
            key={title}
            className={twMerge(
              'py-7 text-body11-m text-grayscale-3',
              list.noWrapLists.includes(link) ? 'whitespace-nowrap' : 'whitespace-pre-line'
            )}
          >
            <NavLink
              onClick={() => setRouterType('sub')}
              className={merge(
                'z-10 text-body11-m hover:text-body11-l',
                isActivePath(link, pathname) && 'text-body11-l'
              )}
              href={link && link}
              type="sub"
              isActive={isActivePath(link, pathname)}
              iconVisible={false}
            >
              <span
                className={merge(
                  isActivePath(link, pathname) && 'underline underline-offset-4',
                  'whitespace-pre hover:underline hover:underline-offset-4'
                )}
              >
                {title}
              </span>
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
}
